// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_auth/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_auth/route.tsx");
  import.meta.hot.lastModified = "1730486026878.4314";
}
// REMIX HMR END

import { Outlet } from '@remix-run/react';
import { GeneralErrorBoundary } from '~/components/error-boundary';
// export let loader: LoaderFunction = async ({request}) => {
//   // await requireUser(request)
// };

export default function AuthRoot() {
  return <div>
			<Outlet />
		</div>;
}
_c = AuthRoot;
export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "AuthRoot");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;